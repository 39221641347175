<div class="container-sm min-vh-100 d-flex align-items-center justify-content-center">
  <mat-card *ngIf="startCodeInfo">
    <mat-card-header>
      <mat-card-title>
        Link / QR code voor: {{ startCodeInfo.locationName }} (klas / groep: {{ startCodeInfo.groupName }} van {{ startCodeInfo.teacherName }})
      </mat-card-title>
      <mat-card-subtitle> Controleer de gegevens en scan de QR code of klik op de knop om naar de vragenlijst te gaan. </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content class="mt-2">
      <div class="d-flex">
        <span class="d-flex align-items-center">
          Informatievaardigheden: <mat-icon class="me-4" inline="">{{ startCodeInfo.hasReadingInformationSkills ? "check" : "close" }}</mat-icon>
        </span>
        <span class="d-flex align-items-center">
          Schoolbibliotheek aanwezig: <mat-icon class="me-4" inline="">{{ startCodeInfo.hasSchoolLibrary ? "check" : "close" }}</mat-icon>
        </span>
        <span class="d-flex align-items-center">
          Speciaal onderwijs: <mat-icon class="me-4" inline="">{{ startCodeInfo.isSbo ? "check" : "close" }}</mat-icon>
        </span>
      </div>
      <div class="d-flex justify-content-center">
        <qrcode [qrdata]="qrLink" [width]="200" [errorCorrectionLevel]="'M'"></qrcode>
      </div>
    </mat-card-content>
    <div>
      <mat-label>Aantal vragenlijsten gestart: </mat-label>{{ startCodeInfo.startedFromTemplate }}
      <mat-label class="ms-3">Aantal vragenlijsten afgerond: </mat-label>{{ startCodeInfo.finishedFromTemplate }}
    </div>
    <mat-card-actions>
      <button pmaMdBos mat-raised-button color="primary" type="button" (click)="onStartClick()">Naar vragenlijst</button>
    </mat-card-actions>
  </mat-card>
</div>
