<iframe *ngIf="url; else startcode" class="d-block border-0 w-100 vh-100" [attr.src]="url"></iframe>
<ng-template #startcode>
  <div class="container">
    <div class="col-12 col-xl-10 offset-xl-1">
      <div class="row mb-3">
        <div class="col d-flex justify-content-around mt-1">
          <img src="./assets/images/logo-boekstart.svg" />
          <img src="./assets/images/logo-dbos.svg" />
        </div>
      </div>
      <p>Om een vragenlijst in te vullen kunt u hier uw startcode invoeren.</p>
      <p>De startcode komt overeen met de laatste 6 tekens in de link die in de mail is vermeld.</p>
      <mat-card>
        <mat-card-header>
          <mat-card-title>Startcode</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <form (ngSubmit)="start()" #form="ngForm">
            <mat-form-field appearance="fill" class="flex-grow-1 w-100">
              <input matInput [(ngModel)]="code" #codeRef="ngModel" name="question" required />
              <mat-error *ngIf="codeRef.hasError('required')"> Het veld 'Startcode' is verplicht. </mat-error>
            </mat-form-field>
            <button mat-raised-button color="primary" pmaMdBos>Naar vragenlijst</button>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</ng-template>
