<div class="container-sm min-vh-100 d-flex align-items-center justify-content-center">
  <mat-card *ngIf="startCodeInfo">
    <mat-card-header>
      <mat-card-title> Vragenlijst voor: {{ startCodeInfo.locationName }} </mat-card-title>
    </mat-card-header>
    <mat-card-content class="mt-2">
      <div>Controleer of de gegevens hieronder kloppen en klik op de knop "Naar vragenlijst" of scan de QR-code als de gegevens correct zijn.</div>
      <div class="mt-2 mb-2">Ga naar je {{ questionnaireCode === "PL" ? "juf of meester" : "docent" }} als de gegevens niet correct zijn.</div>
      <div>{{ questionnaireCode === "PL" ? "Groep" : "Klas" }}: {{ startCodeInfo.groupName }}</div>
      <div>{{ questionnaireCode === "PL" ? "Juf of meester" : "Docent" }}: {{ startCodeInfo.teacherName }}</div>
      <div class="d-flex justify-content-center">
        <qrcode [qrdata]="qrLink" [width]="200" [errorCorrectionLevel]="'M'"></qrcode>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button pmaMdBos mat-raised-button color="primary" type="button" (click)="onStartClick()">Naar vragenlijst</button>
    </mat-card-actions>
  </mat-card>
</div>
