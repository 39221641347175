import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { translateProjectCode } from '../../../core/translations/project-code-nl';
import { UriService } from '../../../core/uri.service';
import { QuestionnaireProjectClient, QuestionnaireType, UpdateSampleClient } from '../../../core/webapi';

@Injectable({
  providedIn: 'root',
})
export class ClassCheckPageGuard {
  constructor(
    private uris: UriService,
    private client: QuestionnaireProjectClient,
    private sampleClient: UpdateSampleClient,
  ) {}

  canActivate(snapshot: ActivatedRouteSnapshot): Observable<boolean> {
    const startCode = snapshot.params['id'] ?? '';
    const questionnaireCode = snapshot.url[0].path;
    const type = translateProjectCode(questionnaireCode);
    if (!startCode) {
      return this.navigate(type, '');
    }
    return this.sampleClient
      .updateSample(startCode)
      .pipe(switchMap(() => (['PL', 'VL'].includes(questionnaireCode) ? of(true) : this.navigate(type, startCode))));
  }

  navigate(type: QuestionnaireType, startCode: string | null) {
    return this.client.getProjectGuid(type).pipe(
      map((x) => {
        location.href = `${this.uris.urlResponse}${x}/login/${startCode}`;
        return false;
      }),
    );
  }
}
